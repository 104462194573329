<template lang="pug">

q-header.bg-grey-2
  q-toolbar.q-pa-md
    q-icon.q-mr-md(name="handyman" size="md" color="grey-8")
    .text-h4.text-black <router-link class="text-primary" to="/home">CHeKT Ham</router-link> Manual

</template>

<style>
a { 
  text-decoration: none; 
}
</style>

<script>
export default {
}
</script>
