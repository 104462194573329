<template lang="pug">

q-layout(view="hhh LpR fff")
  //- Layout
  Header

  //- Body
  q-page-container
    q-page  
      q-tabs(v-model="tab" dense align="left" narrow-indicator)
        q-tab(label="ICE" name="ice")
        q-tab(label="Restart ICE" name="restart")  
      q-tab-panels.bg-grey-2(v-model="tab" animated)
        q-tab-panel(name="ice")
          ICE
        q-tab-panel(name="restart")
          RestartICE

</template>

<style>
</style>

<script>
import Header from "./_comps/Header.vue"
import ICE from "./_comps/ICE.vue"
import RestartICE from "./_comps/RestartICE.vue"

export default {
  components: { Header, ICE, RestartICE},
  data(){
    return {
      tab: 'ice'
    }
  },
}
</script>
