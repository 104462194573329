<template lang="pug">
.q-pa-md.q-px-lg

  //- section1
  .q-mb-lg(style="width:500px")
    .text-h5.q-mb-md ICE servers
    q-list.bg-white(bordered)
      q-item(
        v-for="(v, i) in iceServerList" 
        clickable v-ripple 
        :active="v.active" 
        @click="onClickActiveState(i)"
        @dblclick="onDBClickSelectServer(v)"
      )
        q-item-section(v-if="v.iceUserName || v.icePassword") {{ v.url + `[${v.iceUserName}:${v.icePassword}]`}}
        q-item-section(v-else) {{ v.url[0] }}

    q-list
      q-item.q-pr-none
        q-item-section(side style="width:140px") STUN or TURN URI :
        q-item-section
          q-input(type="url" v-model="serverUrl" outlined dense bg-color="white")

      q-item.q-pr-none
        q-item-section(side style="width:140px") TURN username :
        q-item-section
          q-input(v-model="iceUserName" outlined dense bg-color="white")

      q-item.q-pr-none
        q-item-section(side style="width:140px") TURN password :
        q-item-section
          q-input(v-model="icePassword" outlined dense bg-color="white")

      .full-width.q-my-md.flex.justify-between
        q-btn(label="Add Server" color="white" text-color="black" @click="onClickAddServer")
        q-btn(label="Remove Server" color="white" text-color="black" @click="onClickRemoveServer")
        q-btn(label="Reset to defaults" color="white" text-color="black" @click="onClickReset")
  q-separator

  //- section 2
  .q-my-lg(style="width:500px")
    .text-h5 ICE options
    q-list
      q-item
        q-item-section(side) IceTransports value:
        q-item-section
          .row  
            q-radio(v-model="iceTransports" val="all" label="all")
            q-radio(v-model="iceTransports" val="relay" label="relay")
      q-item
        q-item-section(side) ICE Candidate Pool:
        q-item-section
          .row.flex.items-center
            .col-1 {{ iceCandidatePool }}
            .col-1.text-grey 0
            q-slider.col(v-model="iceCandidatePool" :min="0" :max="10")
            .col-1.q-ml-sm.text-grey 10
      q-item
        q-item-section
          q-checkbox(v-model="getUserMedia" label="Acquire microphone/camera permissions" :disable="isDisabledGetUserMedia")
  q-separator
  
  //- section3
  .q-my-lg
    q-table.sticky-header(
      :rows="rows"
      :columns="columns"
      row-key="time"
      separator="none"
      :style="tableHight"
      :pagenation="pagenation"
      flat
      hide-pagination
    )
    q-btn.q-mt-md(label="Gather candidates" color="white" text-color="black" :disable="isDisabledGatherBtn" @click="onClickStartGathering")
    .text-danger(v-if="error") {{ error }}

</template>

<style>
.sticky-header th{
  position: -webkit-stickyl;
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>>

<script>
export default {
  computed:{
    tableHight:function(){
      return this.rows.length > 0 ? '300px' : '56px'
    },
  },
  data:function(){
    return {
      columns: [
        // Time,	Component	Type,	Foundation,	Protocol,	Address	Port,	Priority,	Mid,	MLine Index,	Username Fragment
        { name: 'time', label: 'Time', field: 'time', align: 'center'},
        { name: 'component', label: 'Component', field: 'component', align: 'center'},
        { name: 'type', label: 'Type', field: 'type', align: 'center' },
        { name: 'foundation', label: 'Foundation', field: 'foundation', align: 'center' },
        { name: 'protocol', label: 'Protocol', field: 'protocol', align: 'center' },
        { name: 'address', label: 'Address', field: 'address', align: 'center' },
        { name: 'port', label: 'Port', field: 'port', align: 'center' },
        { name: 'priority', label: 'Priority', field: 'priority', align: 'center' },
        { name: 'mid', label: 'Mid', field: 'mid', align: 'center' },
        { name: 'mLineIndex', label: 'MLine Index', field: 'mLineIndex', align: 'center' },
        { name: 'userNameFragment', label: 'Username Fragment', field: 'userNameFragment', align: 'center' },
      ],
      rows: [],
      pagenation: {rowsPerPage: 0},

      iceServerList: [
        { url: ["stun:stun.l.google.com:19302"], active: false},
      ],
      serverUrl: '',
      iceUserName: '',
      icePassword: '',
      iceTransports: 'all',
      iceCandidatePool: 0,
      getUserMedia: false,
      error: '',
      deleteServerIndex: null,

      //disabled
      isDisabledGetUserMedia: false,
      isDisabledGatherBtn: false,

      // ICE
      allServersKey: 'servers',
      begin: null,
      pc: null,
      stream: null,
      candidates: null,
    }
  },
  mounted(){
    this.writeServersToLocalStorage()
  },
  methods: {
    //////////////////////////////////////
    // click event
    onClickActiveState: function(i){
      this.iceServerList.forEach(v => v.active = false)
      this.iceServerList[i].active = true
    },
    onDBClickSelectServer: function(e){
      console.log('dbclick :>>', e)
      this.serverUrl= e.url
      this.iceUserName = e.username
      this.icePassword = e.credential
    },

    ///////////////////////////////////////
    // ADD ICE SERVER
    onClickAddServer: function(){
      const scheme = this.serverUrl.split(':')[0]
      if (!['stun', 'stuns', 'turn', 'turns'].includes(scheme)) {
        alert(`URI scheme ${scheme} is not valid`)
        return
      }

      // Store the ICE server as a stringified JSON object in option.value.
      console.log('this.serverUrl:>>', this.serverUrl)
      const iceServer = {
        url: [this.serverUrl],
        username: this.iceUserName,
        credential: this.icePassword,
        active: false
      };
      this.iceServerList.push(iceServer)
      this.serverUrl = this.iceUserName = this.icePassword = ''
      this.writeServersToLocalStorage()

      this.serverUrl = ''
      this.iceUserName = ''
      this.icePassword = ''
    },
    writeServersToLocalStorage: function(){
      let allServers = []
      this.iceServerList.map(v => {
        const server = {url: v.url}
        allServers.push(server)
      })
      localStorage.setItem(this.allServersKey, JSON.stringify(allServers))
      console.log('localStorage :>>', localStorage)
    },
    readServersFromLocalStorage(){
      const storedServers = localStorage.getItem(this.allServersKey)

      if(storedServers === null || this.iceServerList.length === 0){
        this.onClickReset()
      } 
      JSON.parse(storedServers).forEach(server => {
        const iceServer = {
          url: JSON.stringify(server), 
          username: '', 
          credential: '', 
          active: false
        }
        this.iceServerList.push(iceServer)
        console.log('readServersFromLocalStorage :>>',this.iceServerList)
      })
    },

    ///////////////////////////////////////
    // REMOVE 
    onClickRemoveServer: function(){
      this.deleteServerIndex = this.iceServerList.findIndex(v => v.active)
      this.iceServerList.splice(this.deleteServerIndex, 1)
      this.writeServersToLocalStorage()

      this.serverUrl = ''
      this.iceUserName = ''
      this.icePassword = ''
    },    

    ///////////////////////////////////////
    // RESET
    onClickReset: function(){
      localStorage.clear()
      this.iceServerList = [
        { url: ["stun:stun.l.google.com:19302"], active: false},
      ]

      this.serverUrl = ''
      this.iceUserName = ''
      this.icePassword = ''
    },

    ///////////////////////////////////////
    // START : GATHER
    onClickStartGathering: async function(){
      if(this.rows.length) this.rows = []

      this.isDisabledGetUserMedia = true
      if(this.getUserMedia){
        this.stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
      }
      this.isDisabledGatherBtn = true

      // Read the values from the input boxes.
      const iceServers = [];
      for (let i = 0; i < this.iceServerList.length; ++i) {
        iceServers.push(this.iceServerList[i])
      }
      let iceTransports = this.iceTransports

      // Create a PeerConnection with no streams, but force a m=audio line.
      const config = {
        iceServers: iceServers,
        iceTransportPolicy: iceTransports,
        iceCandidatePoolSize: this.iceCandidatePool
      };

      const offerOptions = {offerToReceiveAudio: 1};
      // Whether we gather IPv6 candidates.
      // Whether we only gather a single set of candidates for RTP and RTCP.

      console.log(`Creating new PeerConnection with config=${JSON.stringify(config)}`)

      let desc
      try {
        this.pc = new RTCPeerConnection(config)
        this.pc.onicecandidate = this.iceCallback
        this.pc.onicegatheringstatechange = this.gatheringStateChangeToComplete
        this.pc.onicecandidateerror = this.iceCandidateError
        if (this.stream) this.stream.getTracks().forEach(track => this.pc.addTrack(track, this.stream))
        desc = await this.pc.createOffer(offerOptions)
        console.log('desc:>>', desc)
      } catch (err) {
        this.error = `Error creating offer: ${err}`
        this.isDisabledGatherBtn = false
        return
      }
      this.begin = performance.now()
      this.candidates = []
      this.pc.setLocalDescription(desc)
    },

    ///////////////////////////////////////
    // MAKE STUN/TURN LIST

    // PRE
    formatPriority: function(priority) {
      return [
        priority >> 24,
        (priority >> 8) & 0xFFFF,
        priority & 0xFF
      ].join(' | ');
    },

    // ICE candidate + peer connect
    iceCallback: function (event) {
      const elapsed = ((performance.now() - this.begin) / 1000).toFixed(3);
      const data = { time: elapsed, active: false }

      if (event.candidate) {
        if (event.candidate.candidate === '') return

        const {candidate} = event;
        data.component = candidate.component
        data.type = candidate.type
        data.foundation = candidate.foundation
        data.protocol = candidate.protocol
        data.address = candidate.address
        data.port = candidate.port
        data.priority = this.formatPriority(candidate.priority)
        data.mid = candidate.sdpMid
        data.mLineIndex = candidate.sdpMLineIndex
        data.userNameFragment = candidate.usernameFragment
        this.rows.push(data)
        this.candidates.push(candidate) 
      } else if (!('onicegatheringstatechange' in RTCPeerConnection.prototype)) {
        // should not be done if its done in the icegatheringstatechange callback.
        data.priority = this.getFinalResult()
        this.rows.push(data)
        this.pc.close();
        this.pc = null;
        this.pc = null;
        if (this.stream) {
          this.stream.getTracks().forEach(track => track.stop());
          this.stream = null;
        }
        this.isDisabledGetUserMedia = false
        this.isDisabledGatherBtn = false
      }
    },
    gatheringStateChangeToComplete: function() {
      console.log('this.pc.iceGatheringState :>', this.pc.iceGatheringState)
      if (this.pc.iceGatheringState !== 'complete') return
      const elapsed = ((performance.now() - this.begin) / 1000).toFixed(3);
      const data = {
        time: elapsed,
        priority: this.getFinalResult(),
      }
      this.rows.push(data)

      this.pc.close()
      this.pc = null
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null;
      }
      this.isDisabledGetUserMedia = false
      this.isDisabledGatherBtn = false
    },


    getFinalResult: function() {
      let result = 'Done';

      // if more than one server is used, it can not be determined
      // which server failed.
      if (this.iceServerList.length === 1) {
        const server = this.iceServerList[0].url[0]

        // get the candidates types (host, srflx, relay)
        const types = this.candidates.map(candidate => candidate.type)
        console.log(server, types)

        if(server.indexOf('trun:') === 0 && server.indexOf('?transport=tcp') === -1){
          if(types.indexOf('relay') === -1){
            types.indexOf('srflx') > -1 ? 
              result = 'Authentication failed?' : 
              result = 'Not reachable?'
          }
        }
      }
      console.log(result)
      return result;
    },

    ///////////////////////////////////////
    // error
    iceCandidateError: function(e) {
      // The interesting attributes of the error are
      // * the url (which allows looking up the server)
      // * the errorCode and errorText
      this.error = 'The server ' + e.url + ' returned an error with code=' 
        + e.errorCode + ':\n' + e.errorText + '\n';
    }
  }
}
</script>
