<template lang="pug">
.q-pa-md.q-px-lg
  .row
    .col
      video.full-width(ref="localVideo" playsinline autoplay muted style="border: 1px solid black")
      div Local candidate id: {{ localCandidateId }}
    .col
      video.full-width(ref="remoteVideo" playsinline autoplay muted style="border: 1px solid black")
      div Remote candidate id: {{ remoteCandidateId }}
  
  .q-gutter-md
    q-btn(
      label="Start" 
      color="white" text-color="black"
      :disable="isDisabledStartBtn"
      @click="onClickStart"
    )
    q-btn(
      label="Call" 
      color="white" text-color="black"
      :disable="isDisabledCallBtn"
      @click="onClickCall"
    )
    q-btn(
      label="Restart ICE" 
      color="white" text-color="black"
      :disable="isDisabledRestartBtn"
      @click="onClickRestart"
    )
    q-btn(
      label="Hang Up" 
      color="white" text-color="black"
      :disable="isDisabledHangUpBtn"
      @click="onClickHangUp"
    )
</template>
<script>
export default {
  data(){
    return {
      isDisabledStartBtn: false,
      isDisabledCallBtn: true,
      isDisabledRestartBtn: true,
      isDisabledHangUpBtn: true,

      pc1: null,
      pc2: null,
      localVideo: null,
      remoteVideo: null,
      localStream: null,
      remoteStream: null,
      localCandidateId: '',
      remoteCandidateId: '',
      useSelectedCandidatePairChange: null,
      offerOptions: {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
      }
    }
  },
  mounted: function(){
    this.localVideo = this.$refs['localVideo']
    this.remoteVideo = this.$refs['remoteVideo']
    this.useSelectedCandidatePairChange = window.RTCIceTransport && 'onselectedcandidatepairchange' in RTCIceTransport.prototype;
  },
  methods: {
    ///////////////////////////
    // tools
    getName : function(pc) {
      return (pc === this.pc1) ? 'pc1' : 'pc2'
    },
    getOtherPc: function(pc) {
      return (pc === this.pc1) ? this.pc2 : this.pc1
    },
    gotStream(stream){
      console.log('Received local stream')
      this.localVideo.srcObject = stream
      this.localStream = stream
      this.isDisabledCallBtn = false
    },

    ///////////////////////////
    // Button
    onClickStart : async function(){
      console.log('Requesting local stream')
      this.isDisabledStartBtn = true
      try{
        const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true})
        this.gotStream(stream)
      } catch(err){
        alert(`getUserMedia() error: ${err.name}`)
      }
    },
    onClickRestart : async function(){
      this.isDisabledRestartBtn = true
      this.offerOptions.iceRestart = true // 이게 핵심이네. 샘플도 여기서 설정했네
      console.log('pc1 createOffer restart')
      try {
        const offer = await this.pc1.createOffer(this.offerOptions)
        this.onCreateOfferSuccess(offer)
      } catch(err){
        this.onCreateSessionDescriptionError(err)
      }
    },
    onClickCall : function(){
      this.isDisabledCallBtn = true
      this.isDisabledHangUpBtn = false
      console.log('Starting Call')

      // Stream checking
      const videoTracks = this.localStream.getVideoTracks()
      const audioTracks = this.localStream.getAudioTracks()
      if (videoTracks.length > 0) {
        console.log(`Using video device: ${videoTracks[0].label}`);
      }
      if (audioTracks.length > 0) {
        console.log(`Using audio device: ${audioTracks[0].label}`);
      }

      // Start PeerConnection
      const servers = null
      this.pc1 = new RTCPeerConnection(servers)
      console.log('Created local peer connection object pc1')
      this.pc1.onicecandidate = e => this.onIceCandidate(this.pc1, e)

      this.pc2 = new RTCPeerConnection(servers)
      console.log('Created remote peer connection object pc2')
      this.pc2.onicecandidate = e => this.onIceCandidate(this.pc2, e)

      this.pc1.oniceconnectionstatechange = e => {
        this.onIceStateChange(this.pc1, e)
        if(this.pc1 && this.pc1.iceConnectionState === 'connected') {
          this.isDisabledRestartBtn = false
        }
      }
      this.pc2.oniceconnectionstatechange = e => this.onIceStateChange(this.pc2, e) // 이건 정확히 어떤 역할을 하는 것일까?
      this.pc2.ontrack = this.gotRemoteStream

      this.localStream.getTracks().forEach(track => this.pc1.addTrack(track, this.localStream))
      console.log('Added loacal stream to pc1')

      console.log('pc1 createOffer start')
      this.pc1.createOffer(this.offerOptions).then(this.onCreateOfferSuccess, this.onCreateSessionDescriptionError)
    },
    gotRemoteStream: function(e){
      if(this.remoteVideo.srcObject !== e.streams[0]){
        this.remoteVideo.srcObject = e.streams[0]
        console.log('pc2 received remote stream')
      }
    },
    onClickHangUp : function(){
      console.log('Ending Call')
      this.pc1.close()
      this.pc2.close()
      this.pc1 = null
      this.pc2 = null

      this.isDisabledHangUpBtn = true
      this.isDisabledRestartBtn = true
      this.isDisabledCallBtn = false
    },

    ////////////////////////////
    // ICE tools
    onIceCandidate: function(pc, event){
      this.getOtherPc(pc)
        .addIceCandidate(event.candidate)
        .then(() => this.onAddIceCandidateSuccess(pc), err => this.onAddIceCandidateError(pc, err))
      console.log(`${this.getName(pc)} ICE candidate:\n${event.candidate ? event.candidate.candidate : '(null)'}`)
    },
    onIceStateChange: function(pc, event){
      if(pc){
        console.log(`${this.getName(pc)} ICE state: ${pc.iceConnectionState}`)
        console.log('ICE state change event: ', event)
        console.log("🚀 ~ ICE state connectionState", pc.connectionState)
        console.log('🚀 ~ ICE state ice Connection State: ', pc.iceConnectionState)
        console.log('🚀 ~ ICE state ice Gathering State: ', pc.iceGatheringState)
        console.log('🚀 ~ ICE state signaling State: ', pc.signalingState)
        if (!this.useSelectedCandidatePairChange) {
          console.log(this.useSelectedCandidatePairChange)

          if (this.pc.iceConnectionState === 'connected' ||
            this.pc.iceConnectionState === 'completed') {
            this.checkStats(pc)
          }
        }
      }
    },

    onAddIceCandidateSuccess: function(pc) {
      console.log(`${this.getName(pc)} addIceCandidate success`)
    },
    onAddIceCandidateError: function (pc, error) {
      console.log(`${this.getName(pc)} failed to add ICE Candidate: ${error.toString()}`)
    },
    /////////// OFFER /////////
    onCreateOfferSuccess : function (desc){
      console.log(`Offer from pc1`)
      console.log('pc1 setLocalDescription start')
      
      this.pc1.setLocalDescription(desc).then(() => this.onSetLocalSuccess(this.pc1), this.onSetSessionDescriptionError)
      console.log('pc2 setRemoteDescription start')
      this.pc2.setRemoteDescription(desc).then(() => this.onSetRemoteSuccess(this.pc2), this.onSetSessionDescriptionError)
      console.log('pc2 createAnswer start')
      this.pc2.createAnswer().then(this.onCreateAnswerSuccess, this.onCreateSessionDescriptionError)
    },
    /////////// ANSWER /////////
    onCreateAnswerSuccess(desc){
      console.log(`Answer from pc2:`)
      console.log('pc2 setLocalDescription start')
      this.pc2.setLocalDescription(desc).then(() => this.onSetLocalSuccess(this.pc2), this.onSetSessionDescriptionError)
      console.log('pc1 setRemoteDescription start')
      this.pc1.setRemoteDescription(desc).then(() => this.onSetRemoteSuccess(this.pc1), this.onSetSessionDescriptionError)

      if(this.useSelectedCandidatePairChange){
        // getSenders : RTP 패킷을 송신하는 쪽의 RTCRtpSender object를 반환함 
        console.log("🚀 ~ pc1.getSenders ~ iceTransport", this.pc1.getSenders()[0].transport)
        this.pc1.getSenders()[0].transport.iceTransport.onselectedcandidatepairchange = () => {
          this.checkStats(this.pc1)
          if(this.pc1.iceConnectionState === 'connected'){
            this.isDisabledRestartBtn = false
          }
        }
        this.pc2.getSenders()[0].transport.iceTransport.onselectedcandidatepairchange = () => {
          this.checkStats(this.pc2)
        }
      }
    },
    ///////////////////////////////////////
    checkStats: function(pc){
      // 특정 MediaStreamTrack과 전체 연결에 대한 통계를 Promise 형태로 반환
      // parms가 option : null인 경우 전체 반환
      pc.getStats(null).then(results => {
        // figure out the peer's ip
        let activeCandidatePair = null
        let remoteCandidate = null

        results.forEach(report => {
          if(report.type === 'transport'){
            activeCandidatePair = results.get(report.selectedCandidatePairId)
          }
        })
        // Fallback for firefox
        if(!activeCandidatePair){
          results.forEach(report => {
            if (report.type === 'candidate-pair' && report.state === 'succeeded' && report.selected) {
              activeCandidatePair = report;
            }
          })
        }
        if (activeCandidatePair && activeCandidatePair.remoteCandidateId) {
          results.forEach(report => {
            if (report.type === 'remote-candidate' && report.id === activeCandidatePair.remoteCandidateId) {
              remoteCandidate = report;
            }
          });
        }
        console.log(remoteCandidate)
        console.log('activeCandidatePair 이게 뭐지?', activeCandidatePair)
        if (remoteCandidate && remoteCandidate.id) {
          // TODO: update a div showing the remote ip/port?
          pc === this.pc1 ? this.localCandidateId = remoteCandidate.id : this.remoteCandidateId = remoteCandidate.id
        }
      })
    },

    ///////////////////////////////////////
    // Logs
    onCreateSessionDescriptionError: function(error) {
      console.log(`Failed to create session description: ${error.toString()}`)
    },
    onSetLocalSuccess: function(pc) {
      console.log(`${this.getName(pc)} setLocalDescription complete`)
    },
    onSetRemoteSuccess : function(pc) {
      console.log(`${this.getName(pc)} setRemoteDescription complete`)
    },
    onSetSessionDescriptionError: function(error){
      console.log(`Failed to set session description: ${error.toString()}`)
    },
  }
}
</script>